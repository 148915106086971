@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

$search-input-width: 320px;
$search-input-width-wide: 400px;

.root {
    display: block;
    margin: 0;
    min-width: $search-input-width;
    position: relative;
    width: 100%;
    z-index: var(--z-index-tooltip);

    @include mq.mq($from: wide) {
        min-width: $search-input-width-wide;
    }
}

.input {
    font-weight: lighter;
    font-size: var(--font-size-core-m);
    font-family: inherit;
    color: var(--color-foreground-primary-default);
    width: 100%;
    padding: var(--spacing-core-3) var(--spacing-core-4);
    border: var(--border-width-core-regular) solid
        var(--color-background-tertiary-default);
    border-radius: calc(
        var(--border-radius-core-xl) + var(--border-radius-core-m)
    );
    outline: none;

    &::-ms-clear {
        display: none;
    }

    /* Hide clear button & search decoration for WebKit browsers */
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

.clearButton {
    position: absolute;
    right: var(--spacing-core-4);
    top: 50%;
    border: 0;
    padding: var(--spacing-core-2);
    background-color: transparent;
    color: var(--color-background-tertiary-pressed);
    outline: 0;
    cursor: pointer;
    transform: translateY(-50%);
    transition:
        color 0.2s linear,
        transform 0.2s ease-in-out;

    &:focus,
    &:active,
    &:hover {
        color: var(--color-background-tertiary-pressed);
    }

    &:focus {
        outline: var(--spacing-core-025) dotted
            var(--color-foreground-primary-default);
    }
}

%searchButton {
    position: absolute;
    top: 20%;
    left: var(--spacing-core-4);
    padding: 0;
    background: none;
    color: var(--color-foreground-primary-default);
}

.closeButton {
    @extend %searchButton;

    transform: translateY(calc(-50% + 1px));
    top: 50%;

    @include mq.mq($from: desktop) {
        display: none !important;
    }
}

.iconButton {
    @extend %searchButton;

    transform: translateY(-10px);
    transition: color 0.2s linear;
    border-width: var(--border-width-core-regular);
    border-style: solid;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border-color: transparent;

    &:hover {
        color: var(--color-background-tertiary-pressed);
    }

    @include mq.mq($to: tablet) {
        display: none !important;
    }
}

.submitButton {
    @extend %searchButton;

    transform: translateY(-50%);

    &:focus,
    &:active,
    &:hover {
        cursor: default;
    }

    &:not([disabled]) {
        &:hover,
        &:focus {
            cursor: pointer;
            color: var(--color-action-primary-default);
        }

        &:active {
            color: var(--color-action-primary-pressed);
        }
    }

    @include mq.mq($from: desktop) {
        display: none !important;
    }
}

.backdrop {
    display: none;
    position: fixed;
    inset: 0;
    background: var(--color-foreground-primary-default);
    z-index: var(--z-index-backdrop);
    opacity: 0;

    &.open {
        animation: fadeInOpenSearch 0.2s;
        display: block;
        opacity: var(--opacity-core-40);
    }
}

@include mq.mq($from: desktop) {
    .root {
        .input {
            height: var(--sizing-core-12);
            padding: var(--spacing-core-4) var(--spacing-core-6)
                var(--spacing-core-4) var(--spacing-core-12);
            background: var(--color-background-tertiary-disabled);
            box-shadow: 0 0 0 0 var(--color-background-tertiary-disabled);
            transition:
                box-shadow 0.2s ease-in-out,
                border-radius-bottom-left 0.2s ease-in-out,
                border-radius-bottom-right 0.2s ease-in-out;
            border-radius: calc(
                var(--border-radius-core-xl) + var(--border-radius-core-m)
            );
            border: none;

            &:focus {
                background: var(--color-background-primary-default);
            }

            &.open {
                background: var(--color-background-primary-default);
                border-radius: var(--border-radius-core-xl)
                    var(--border-radius-core-xl) 0 0;
                box-shadow: 0 0 0 0 var(--color-background-primary-default);
                transition: none;
            }
        }
    }
}

@include mq.mq($to: tablet) {
    .compact {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: var(--sizing-core-12);
    
        .input {
            height: var(--sizing-core-12);
            padding: var(--spacing-core-3) var(--spacing-core-16)
                var(--spacing-core-3) var(--spacing-core-12);
            border-radius: 0;
            border-top: none;
        }
    
        .clearButton {
            right: var(--spacing-core-14);
        }
    
        .submitButton {
            top: 0;
            right: 0;
            left: initial;
            background-color: var(--color-action-primary-default);
            color: var(--color-background-primary-default);
            transform: none;
            width: var(--sizing-core-12);
            height: var(--sizing-core-12);
            border-radius: 0;
    
            &:focus,
            &:active,
            &:hover {
                color: var(--color-background-primary-default);
                background-color: var(--color-action-primary-disabled);
            }
        }
    }
}

@keyframes fadeInOpenSearch {
    0% {
        opacity: 0;
    }

    100% {
        opacity: var(--opacity-core-40);
    }
}
