.panelBody {
    height: 100%;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
}

.spinnerWrapper {
    display: block;
    margin: var(--spacing-core-4);
    text-align: center;

    .spinnerHelpText {
        display: block;
        position: relative;
        text-align: center;
        padding: var(--spacing-core-4);
        color: var(--color-foreground-primary-hover);
    }
}

.noResults {
    padding: var(--spacing-core-4);
}
