.root {
    flex: 0 1 auto;
    height: 100%;
    display: flex;
    align-items: center;
    transition: opacity 0.2s linear;
}

.alignCenter {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

.alignRight {
    margin-left: auto;
}

.disabled {
    flex-shrink: 0;
}
