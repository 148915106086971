@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.root {
    background-color: var(--color-foreground-primary-default);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: var(--sizing-core-16);
    animation: slideIn 0.3s ease-in;
    overflow: hidden;
    transition:
        height 0.2s ease-in-out,
        transform 0.2s ease-in-out;
    z-index: calc($z-index-header + 1);

    .title {
        position: relative;
        color: var(--color-background-primary-default);
        background: inherit;
        height: 100%;
        padding-right: var(--spacing-core-8);

        &HasIcon {
            padding-left: var(--spacing-core-12);
        }

        .icon {
            left: var(--spacing-core-4);
        }

        .dateInfo {
            margin-right: var(--spacing-core-2);
        }

        .chevron {
            right: var(--spacing-core-4);
        }

        svg {
            fill: var(--color-background-primary-default);
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
        }
    }

    @include mq.mq($from: desktop) {
        height: var(--sizing-core-10);
    }
}

.hide {
    transform: translateY(-100%);
    height: 0;
}
