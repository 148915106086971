@use '../../styles/vars' as *;

$foldout-items: 8;
$transition-delay: 0.3s;
$transform: calc(var(--spacing-core-1) * -1);

.menuDropdown {
    align-items: center;
    display: flex;
    position: relative;

    &MountPoint {
        left: 50%;
        top: var(--spacing-core-8);
        position: absolute;
        z-index: $z-index-header;

        &::after {
            content: '';
            position: absolute;
            top: var(--spacing-core-8);
            left: 50%;
            height: var(--sizing-core-4);
            width: var(--sizing-core-4);
            border-left: var(--border-width-core-regular) solid
                var(--color-background-secondary-hover);
            border-top: var(--border-width-core-regular) solid
                var(--color-background-secondary-hover);
            background-color: var(--color-background-primary-default);
            transform: translateX(-50%) rotate(45deg);
            opacity: 0;
            transition:
                opacity,
                top 0s ease;
        }
    }

    &List {
        position: absolute;
        top: var(--spacing-core-10);
        width: 280px;
        max-height: 0;
        opacity: 0;
        transform: translateY($transform);
        margin: 0;
        list-style: none;
        overflow: hidden;
        background-color: var(--color-background-primary-default);
        color: var(--color-foreground-primary-default);
        transition:
            visibility,
            opacity,
            transform 0s ease;
        visibility: hidden;
        padding: 0;
        left: -140px;
        border-radius: var(--border-radius-core-l);

        .menuItem {
            display: block;
            opacity: 0;
            margin: 0 var(--spacing-core-4);
            padding: 0;

            + .menuItem {
                border-top: var(--border-width-core-regular) solid
                    var(--color-background-secondary-hover);
            }

            .menuItemIcon {
                position: relative;
                top: var(--spacing-core-1);
            }

            &.menuInlineItemActive {
                border-bottom: none;
            }

            a:hover {
                outline: none;
            }

            &Anchor {
                padding: var(--spacing-core-4) 0;
            }
        }
    }

    &Button {
        padding: 0 var(--spacing-core-3);
        color: inherit;

        &Text {
            transition: color 0.2s ease-in-out;
            position: relative;
            white-space: nowrap;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: var(--spacing-core-025);
                width: 100%;
                height: var(--sizing-core-05);
                background-color: var(--color-action-primary-default);
                transform: translateY($transform);
                opacity: 0;
                transition:
                    transform 0.2s ease-in-out,
                    opacity 0.2s ease-in-out;
            }
        }
    }

    &Icon {
        position: relative;

        &Active {
            transform: rotate(180deg);
        }
    }

    &User &Icon {
        position: absolute;
        margin: 0 var(--spacing-core-2);
        top: var(--spacing-core-1);
        right: calc(var(--spacing-core-1) * -1);
    }

    &User {
        max-width: 162px;
        min-width: var(--sizing-core-18);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: var(--spacing-core-6);
    }

    /* extra specificity for webpack css order in production */
    & .menuDropdownUser {
        color: var(--color-action-primary-default);
    }

    &Open {
        .menuDropdownMountPoint {
            visibility: visible;

            &::after {
                opacity: var(--opacity-core-100);
                top: var(--spacing-core-8);
                transition-duration: $transition-delay;
                transition-delay: $transition-delay;
            }
        }

        .menuDropdownList {
            transition-duration: $transition-delay;
            transition-delay: $transition-delay;
            visibility: visible;
            max-height: 600px;
            opacity: var(--opacity-core-100);
            transform: translateY(0);
            box-shadow:
                0 var(--sizing-core-4) var(--sizing-core-5) 0
                    var(--color-background-tertiary-pressed),
                0 0 var(--sizing-core-025) 0
                    var(--color-background-tertiary-pressed);

            .menuItemIcon {
                position: relative;
                top: var(--spacing-core-1);
            }
        }

        .menuItem {
            opacity: var(--opacity-core-100);
        }

        .menuDropdownButton {
            &Text {
                color: var(--color-action-primary-default);

                &::after {
                    opacity: var(--opacity-core-100);
                    transform: translateY(0);
                }
            }

            .menuDropdownIcon {
                color: var(--color-action-primary-default);
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.personalNavMenu {
    .menuDropdownMountPoint {
        top: var(--spacing-core-2);
    }
}
