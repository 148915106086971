@use '../../../../../../styles/vars' as *;
@use '../../../../../../styles/mq' as mq;

.wrapper {
    background: var(--color-background-primary-default);
    display: flex;
    flex-flow: row wrap;
}

.linksWrapper {
    padding: 0 var(--spacing-core-6) var(--spacing-core-6) var(--spacing-core-6);
    max-width: $grid-width-max;
    margin: 0 auto;
    display: flex;
    flex-grow: 1;
    flex-flow: row wrap;
}

.linksGroup {
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-core-8) var(--spacing-core-6) 0;
    width: 33%;

    &:last-child {
        padding: 0 0 var(--spacing-core-8) 0;
    }
}

.linksGroupHeader {
    word-break: break-all;
    margin: var(--spacing-core-6) 0 var(--spacing-core-4) 0;
}

.link {
    color: inherit;
    display: block;
    height: inherit;
    margin-bottom: var(--spacing-core-2);
    text-align: left;

    &:hover {
        text-decoration: none;
    }

    > * {
        color: inherit !important;
    }
}

@include mq.mq($from: 1279px) {
    .linksGroup {
        width: 20%;
    }
}
