.footer:has(*) {
    background: var(--color-background-tertiary-disabled);
    padding: var(--spacing-core-6);
    width: 100%;

    section {
        padding: var(--spacing-core-4) 0;
    }

    @media print {
        display: none;
    }
}
