@use '../../../../../../styles/mq' as mq;

.panelHeader {
    $root: &;

    position: relative;
    display: flex;
    align-items: center;
    border-bottom: var(--border-width-core-regular) solid
        var(--color-background-secondary-pressed);

    /* ensure header has height when no children are provided, but icons are */
    min-height: var(--sizing-core-12);

    .content {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-start;
        padding: var(--spacing-core-2) var(--spacing-core-4);
        height: 100%;
        text-align: left;
        min-height: var(--sizing-core-12);
    }

    .closeButton {
        right: 0;
    }

    .backButton {
        left: 0;
    }

    .closeButton,
    .backButton {
        color: inherit;

        &:hover,
        &:hover #{$root}__back-icon {
            color: var(--color-foreground-secondary-default);
        }

        &:focus,
        &:active,
        &:focus-visible {
            outline: var(--color-action-primary-default) auto
                var(--spacing-core-025);
        }
    }
}

.equalizeNavbar {
    height: var(--sizing-core-16);
    min-height: var(--sizing-core-16); /* fix for flexbox */
}

@include mq.mq($from: desktop) {
    /* remark: do we actual want to equalize the current navbar? Then we should add $top-nav-height, otherwise the name is confusing. */
    .equalizeNavbar {
        height: var(--sizing-core-24);
        min-height: var(--sizing-core-24); /* fix for flexbox */
    }
}
