.root {
    background-color: var(--color-background-primary-default);
    padding: var(--spacing-core-4);
    box-shadow: 0 var(--sizing-core-05) var(--sizing-core-1)
        var(--color-background-secondary-pressed);
    transform: translateY(-110%);
    transition: transform 0.2s ease-in-out;
    justify-content: start;
}

.show {
    transform: translateY(0);
}

.button {
    /** Added the important style because it sometimes gets overwritten by vendor.css styling **/
    position: relative;
    justify-content: flex-start;
    width: 100%;
    border-radius: var(--border-radius-core-xl) !important;
    background-color: var(--color-background-tertiary-disabled) !important;
    color: var(--color-foreground-secondary-default) !important;
    transition: none;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    box-shadow: none;

    span {
        font-weight: normal !important;
    }
}

.icon {
    color: var(--color-foreground-primary-default);
}

.text {
    margin: 0;
}
