@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

.accordion {
    @include mq.mq($from: desktop) {
        display: none;
    }
    
    details + hr {
        margin: 0;
    }
}


.title {
    background-color: inherit;
    padding-left: 0;
}

.desktopList {
    @include mq.mq($to: tablet) {
        display: none;
    }
}

.list {
    list-style: none;
    padding: 0;
}
