$grid-width-max: 1488px; /* 1440 plus 2 x 24px (padding) */

$header-height: 132px;
$header-height-compact: 145px;
$header-menu-third-item-breakpoint: 1319px;
$z-index-header: var(--z-index-drawer);
$z-index-header-overlay: calc($z-index-header + 50);

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: var(--opacity-core-100);
    }
}

@keyframes exit {
    0% {
        opacity: var(--opacity-core-100);
    }

    100% {
        opacity: 0;
    }
}
