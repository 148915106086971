@use '../../../../../../styles/vars' as *;
@use '../../../../../../styles/mq' as mq;

.headerContainer {
    align-items: center;
    display: flex;
    position: sticky;
    padding-bottom: var(--spacing-core-4);
    justify-content: flex-end;
    top: 0;
}

.title {
    width: 100%;
    text-align: center;
}

@include mq.mq($from: tablet) {
    .headerContainer {
        padding-bottom: var(--spacing-core-6);
    }
}
