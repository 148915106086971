@use '../../styles/vars' as *;

@keyframes slideOutIn {
    0% {
        opacity: var(--opacity-core-100);
        transform: translateY(0);
    }

    20% {
        opacity: 0;
        transform: translateY(-150%);
    }

    80% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: var(--opacity-core-100);
        transform: translateY(0);
    }
}

.root {
    position: relative;
    display: inline-block;
}

.button {
    margin-left: var(--spacing-core-1);
    overflow: hidden;

    &:hover {
        text-decoration: none;
    }
}

.discountPrice {
    color: var(--color-action-discount-default);
}

.priceWrapper {
    margin-left: var(--spacing-core-2);
    position: relative;
    z-index: calc($z-index-header + 1);
    transition: all 600ms ease-in-out;
    animation: slideOutIn 0.6s ease-in-out;
}

.price,
.discountPrice {
    display: block;
}

.srOnly {
    position: absolute;
    left: -10000px;
    top: auto;
    width: var(--sizing-core-025);
    height: var(--sizing-core-025);
    overflow: hidden;
}
