$animation-duration: 0.3s;
$animation-timing-function: ease-in-out;
$container-max-width: 1488px;
$desktop: 1024px;
$grid-width-max: 1488px; /* 1440 plus 2 x 24px (padding) */

%link {
    color: var(--color-foreground-primary-default);
    background: inherit;
    height: 100%;

    &:hover {
        text-decoration: none;
        color: var(--color-action-primary-default);
    }
}
