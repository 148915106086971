@use './styles/vars' as *;
@use './styles/mq' as mq;

$main-menu-width: 283px;
$main-menu-width-small: 280px;
$main-menu-width-large: 360px;

.container {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 var(--spacing-core-6);
    width: 100%;
    height: var(--sizing-core-24);
    max-width: $grid-width-max;
    transition: transform 0.2s ease-out;
    color: var(--color-foreground-primary-default);
    background-color: var(--color-background-primary-default);
}

.mainMenu {
    width: auto;
    min-width: $main-menu-width;
    margin: 0;
    display: flex;
    height: 100%;
    padding: 0;
    list-style: none;
    
    @include mq.mq($from: 1210px) {
        min-width: $main-menu-width-small;
        margin: 0 var(--spacing-core-2);
    }

    @include mq.mq($from: 1320px) {
        min-width: $main-menu-width-large;

        #dropDownRecipes {
            display: none;
        }
    }

    @include mq.mq($to: $header-menu-third-item-breakpoint) {
        & > :nth-last-child(2) {
            display: none;
        }

        #dropDownRecipes {
            display: block;
        }
    }
}

.searchContainer {
    width: auto;
    flex-grow: 4;
}

.personalNav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    min-width: 280px;
    width: auto;

    .personalNavMenu {
        align-items: center;
        display: flex;
        height: 100%;
        margin: 0;
        list-style: none;
        padding: 0;

        @include mq.mq($to: 1209px) {
            & > li:last-child > button:first-child {
                p {
                    display: none;
                }
            }
        }
    }
}

.caret {
    position: relative;
    z-index: calc($z-index-header + 2);

    &.open {
        transform: rotate(180deg);
    }
}
