@use '../../styles/vars' as *;

.accordionItem {
    @extend %link;

    height: fit-content;
    display: block;
    padding-top: var(--spacing-core-2);
    max-width: 100%;

    > * {
        color: inherit !important;
    }
}
