.navLogo {
    padding: 0 var(--spacing-core-05) var(--spacing-core-05);

    .logo {
        height: var(--sizing-core-8);
        width: 100%;
    }

    &:focus {
        outline: var(--sizing-core-025) dotted
            var(--color-foreground-primary-default);
    }
}
