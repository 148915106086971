@use './styles/vars' as *;
@use './styles/mq' as mq;

.root {
    background: var(--color-background-primary-default);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: calc($z-index-header + 1);
    transition: all 0.3s ease-in-out;

    &::after {
        content: '';
        display: block;
        width: 100%;
        left: 0;
        right: 0;
        height: var(--sizing-core-025);
        background-color: var(--color-background-tertiary-hover);
        opacity: var(--opacity-core-50);
        bottom: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    @media print {
        display: none;
    }
}

.placeholder {
    height: $header-height-compact;

    &.focused {
        height: var(--sizing-core-16);
    }

    @media print {
        display: none;
    }
}

@include mq.mq($from: desktop) {
    .root {
        &.focused {
            height: var(--sizing-core-24);
            transition: none;
        }
    }

    .placeholder {
        width: 100%;
        height: $header-height;

        &.focused {
            height: var(--sizing-core-24);
        }
    }
}

.placeholderLarge {
    height: calc($header-height-compact + var(--sizing-core-16));

    @include mq.mq($from: desktop) {
        height: calc($header-height + var(--sizing-core-10));
    }
}

.hide {
    @include mq.mq($to: desktop) {
        height: var(--sizing-core-16);

        &::after {
            content: none;
        }
    }

    @include mq.mq($from: desktop) {
        transform: translateY(-$header-height);
    }
}
