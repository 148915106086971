@use './styles/vars' as *;

.container {
    margin-inline: auto;
    margin: 0 auto;
    padding: 0 var(--spacing-core-6);
    max-width: $grid-width-max;

    a {
        color: inherit;
        text-decoration: none;
    }

    .customerLinks {
        display: grid;
    }

    .socialLinks {
        menu {
            display: flex;
            gap: var(--spacing-core-4);
            justify-content: center;
            list-style: none;
            padding: 0;
        }
    }

    .legalLinks {
        menu {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: var(--spacing-core-4);
            justify-content: center;
            list-style: none;
            padding: 0;
        }
    }

    @media screen and (width >= 1024px) {
        .customerLinks {
            grid-template-columns: repeat(4, 1fr);
        }

        .legalLinks menu {
            flex-direction: row;
        }
    }
}
