@use '../../../../styles/vars' as *;
@use '../../../../styles/mq' as mq;

$suggestion-transition-timing: 0.2s ease-in-out;

@keyframes showSuggestions {
    0% {
        opacity: var(--opacity-core-100);
        transform: translateY(-100%);
    }

    100% {
        opacity: var(--opacity-core-100);
        transform: translateY(0);
    }
}

.suggestionsContainer {
    position: absolute;
    top: calc(100% - var(--spacing-core-1));
    left: 0;
    width: 100%;
    padding: 0;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.suggestions {
    padding: 0 0 var(--spacing-core-4);
    background-color: var(--color-background-primary-default);
    border-radius: 0 0 var(--spacing-core-6) var(--spacing-core-6);
    overflow: hidden;
    margin: 0;
    list-style: none;
    transition: all 0.3s ease-in-out;
    animation: showSuggestions 300ms ease-in;
}

.suggestionSection {
    background-color: var(--color-background-primary-hover);

    &Text {
        display: inline-block;
        margin: 0 var(--spacing-core-3);
        padding: var(--spacing-core-1);
        z-index: calc($z-index-header + 1);
        color: var(--color-foreground-primary-default);
    }
}

.link {
    display: flex;
    align-items: center;
    height: var(--sizing-core-10);
    padding: var(--spacing-core-2);
    color: inherit;
    border-radius: var(--border-radius-core-s);
    text-decoration: none;
    position: relative;
    transition: background-color $suggestion-transition-timing;

    &:hover,
    .highlighted & {
        cursor: pointer;
        background-color: var(--color-action-primary-disabled);
        color: var(--color-foreground-primary-default);

        .icon {
            color: var(--color-action-primary-default);
        }

        .caret {
            opacity: var(--opacity-core-100);
        }
    }
}

@include mq.mq($to: tablet) {
    .compact {
        position: fixed;
        top: var(--spacing-core-12);
        bottom: 0;
        left: 0;
        overflow: auto;
        border: none;
        max-height: calc(100vh - var(--sizing-core-12));
        box-shadow: none;
        width: 100%;
        padding: var(--spacing-core-2) 0;
        background-color: var(--color-background-primary-default);
        border-radius: var(--border-radius-core-s);
    }
}
