@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

.root {
    position: absolute;
    display: block;
    top: var(--spacing-core-16);
    right: var(--spacing-core-4);
    z-index: calc($z-index-header + 3) !important;
    padding: var(--spacing-core-4) var(--spacing-core-12) var(--spacing-core-4)
        var(--spacing-core-4);
    background-color: var(--color-foreground-primary-default);
    border-radius: var(--border-radius-core-s);
    width: 279px;
    box-sizing: border-box;
    transition: opacity 300ms ease-in;
    animation: appear 300ms ease-in;

    &::before {
        content: '';
        position: absolute;
        top: calc(var(--spacing-core-1) * -1);
        left: 82%;
        height: var(--sizing-core-4);
        width: var(--sizing-core-4);
        border-radius: var(--border-radius-core-s);
        background-color: var(--color-foreground-primary-default);
        transform: translateX(-50%) rotate(45deg);
    }

    .text {
        color: var(--color-background-primary-default);
        margin: 0;
        height: var(--sizing-core-18);

        .button {
            position: absolute;
            right: var(--spacing-core-4);
            top: var(--spacing-core-4);
            background: inherit;
            height: var(--sizing-core-4);

            .close {
                color: var(--color-background-primary-default);
            }
        }
    }
}

.exit {
    transition: opacity 300ms ease-out;
    animation: exit 300ms ease-in;
}

@include mq.mq($from: desktop) {
    .root {
        top: var(--spacing-core-20);
    }
}
