@use '../../../../styles/vars' as *;

@keyframes nextPane {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes prevPane {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.pane {
    position: relative;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: $z-index-header-overlay;
    overflow: hidden auto;
    background-color: var(--color-background-primary-default);

    &.active {
        z-index: calc($z-index-header-overlay + 1);
    }
}

.next {
    animation: nextPane 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
}

.prev {
    animation: prevPane 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
}