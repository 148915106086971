.root {
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    appearance: none;
    outline: none;
    padding: 0;
    height: var(--sizing-core-6);
    width: var(--sizing-core-6);
}
