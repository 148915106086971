.pane {
    position: relative;
    padding-top: var(--spacing-core-4);
    background-color: var(--color-background-tertiary-disabled);
    min-height: 100%;

    .menuList {
        padding: 0 !important;
        margin: 0 0 var(--spacing-core-4);
        list-style: none;

        &:last-child {
            padding-bottom: var(--spacing-core-12) !important;
        }
    }
}
