.root {
    position: relative;
    cursor: pointer;
}

.track {
    position: relative;
    width: var(--sizing-core-14);
    height: var(--sizing-core-8);
    transition:
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: var(--border-radius-core-l);
    background-color: var(--color-background-tertiary-default);
    color: var(--color-background-primary-default);
}

.thumb {
    width: var(--sizing-core-8);
    height: var(--sizing-core-8);
    box-shadow:
        0 var(--sizing-core-01) var(--sizing-core-04)
            var(--color-background-tertiary-disabled),
        0 var(--sizing-core-025) var(--sizing-core-01)
            var(--color-background-tertiary-hover);
    border-radius: 50%;
    background-color: currentColor;
    cursor: pointer;
    outline: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    top: var(--spacing-core-05);
    left: var(--spacing-core-05);
    position: absolute;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input {
    opacity: 0;
    position: absolute;

    &:checked {
        ~ .track {
            background-color: var(--color-action-primary-default);

            .thumb {
                transform: translateX(var(--spacing-core-05));
            }
        }
    }

    &:disabled {
        ~ .track {
            cursor: default;
            opacity: var(--opacity-core-40);

            .thumb {
                cursor: default;
            }
        }
    }

    &:focus ~ div {
        outline: var(--spacing-core-05) solid
            var(--color-action-primary-pressed);
    }
}
