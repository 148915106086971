.panelHeader {
    padding: 0;
    border-bottom-color: var(--color-background-tertiary-default) !important;

    .headerLink {
        color: var(--color-action-primary-default);
        text-decoration: none;
        padding: 0;
        transform: none;

        &:focus,
        &:active,
        &:focus-visible {
            outline: var(--color-action-primary-default) auto
                var(--spacing-core-025);
        }
    }

    button {
        padding: 0 var(--spacing-core-6);
        transform: scale(1.2);
    }
}

.panelBody {
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
}
