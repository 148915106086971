@use 'sass:string';
@use "sass:map";
@use "sass:meta";

/* Libsass version of guardian/sass-mq
 https://github.com/guardian/sass-mq/

 Breakpoint list

 Name your breakpoints in a way that creates a ubiquitous language
 across team members. It will improve communication between
 stakeholders, designers, developers, and testers.

 @type Map
 @link https://github.com/sass-mq/sass-mq#seeing-the-currently-active-breakpoint Full documentation and examples */
$mq-breakpoints: (
    phone: (
        max: 399px,
    ),
    phablet: (
        min: 400px,
        max: 767px,
    ),
    tablet: (
        min: 768px,
        max: 1023px,
    ),
    desktop: (
        min: 1024px,
        max: 1535px,
    ),
    wide: (
        min: 1536px,
    ),
) !default;

/* Helper functions */
@function mq-get-breakpoint-width($name) {
    @if map.has-key($mq-breakpoints, $name) {
        @return map.get($mq-breakpoints, $name);
    } @else {
        @warn "Breakpoint #{$name} does not exist";
    }
}

@function mq-get-breakpoint-min-width($name) {
    $map: mq-get-breakpoint-width($name);
    @if map.has-key($map, min) {
        @return map.get($map, min);
    } @else {
        @warn "Breakpoint #{$name} does not have MIN value";
    }
}

@function mq-get-breakpoint-max-width($name) {
    $map: mq-get-breakpoint-width($name);
    @if map.has-key($map, max) {
        @return map.get($map, max);
    } @else {
        @warn "Breakpoint #{$name} does not have MAX value";
    }
}

/* Media Query mixin
 Usage:
 .element {
 @include mq.mq($from: mobile) {
 color: red;
 }
 @include mq.mq($to: tablet) {
 color: blue;
 }
 @include mq.mq(mobile, tablet) {
 color: green;
 }
 @include mq.mq($from: tablet, $and: "(orientation: landscape)") {
 color: teal;
 }
 @include mq.mq(950px) {
 color: hotpink;
 }
 } */
@mixin mq($from: false, $to: false, $and: false, $target: 'only screen') {
    /* Initialize variables */
    $min-width: 0;
    $max-width: 0;
    $mediaquery: '';

    /* From: this breakpoint (inclusive) */
    @if $from {
        @if meta.type-of($from) == number {
            $min-width: $from;
        } @else {
            $min-width: mq-get-breakpoint-min-width($from);
        }
    }

    /* To: that breakpoint (inclusive) */
    @if $to {
        @if meta.type-of($to) == number {
            $max-width: $to;
        } @else {
            $max-width: mq-get-breakpoint-max-width($to);
        }
    }

    @if $min-width != 0 {
        $mediaquery: '#{$mediaquery} and (min-width: #{$min-width})';
    }
    @if $max-width != 0 {
        $mediaquery: '#{$mediaquery} and (max-width: #{$max-width})';
    }
    @if $and {
        $mediaquery: '#{$mediaquery} and #{$and}';
    }

    @if $mediaquery != '' or $target != 'only screen' {
        $mediaquery: string.unquote('#{$target}#{$mediaquery}');

        @media #{$mediaquery} {
            @content;
        }
    }
}
