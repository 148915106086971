@use '../../../../../../styles/vars' as *;
@use '../../../../../../styles/mq' as mq;

.root {
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-2);
    padding-top: var(--spacing-core-4);
    justify-content: center;
    position: absolute;
    width: 100%;
    background: var(--color-background-primary-default);
}

@include mq.mq($from: tablet) {
    .root {
        flex-direction: row;
        gap: var(--spacing-core-4);
        padding-top: var(--spacing-core-6);
    }
}
