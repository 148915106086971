@use '../../../../../../styles/vars' as *;
@use '../../../../../../styles/mq' as mq;

.root {
    max-width: 496px; /* 31 * $global-spacer-width */
    width: 100%;
    overflow: auto;
    padding-bottom: 160px; /* footer */
}

.content {
    width: 100%;
    padding-left: var(--spacing-core-4);
    padding-right: var(--spacing-core-4);
    padding-bottom: var(--spacing-core-4);

    @include mq.mq($from: tablet) {
        padding-left: var(--spacing-core-6);
        padding-right: var(--spacing-core-6);
        padding-bottom: var(--spacing-core-6);
    }
}

.contentContainer {
    height: 100%;
    overflow: hidden auto;
    scrollbar-width: thin;
}

.showCloseButton {
    display: flex;
}
