@use './styles/vars' as *;

.navBar {
    height: var(--sizing-core-16);
    padding: 0 var(--spacing-core-4);
    transition: transform 0.2s ease-out;
    width: 100%;
    color: var(--color-foreground-primary-default);
    background-color: var(--color-background-primary-default);
    display: flex;
    align-items: center;
    position: relative;
    z-index: calc($z-index-header + 1);

    &::after {
        content: '';
        display: block;
        width: 100%;
        left: 0;
        right: 0;
        height: var(--sizing-core-025);
        background-color: var(--color-background-secondary-hover);
        opacity: var(--opacity-core-50);
        bottom: 0;
        position: absolute;
    }
}

%toggleBar {
    background: currentcolor;
    border-radius: var(--border-radius-core-s);
    display: block;
    height: var(--sizing-core-05);
    left: 0;
    opacity: var(--opacity-core-100);
    position: absolute;
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
    width: 100%;
}

.toggle {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 0 var(--spacing-core-4) 0 0;
    padding: 0 0 var(--spacing-core-3);
    color: inherit;

    &Bars {
        height: var(--sizing-core-5);
        position: relative;
        width: var(--sizing-core-6);
    }

    &Text {
        position: absolute;
        color: var(--color-foreground-secondary-default);
        top: var(--spacing-core-5);
        left: 0;
        font-size: 10px !important;
    }

    &Bar1 {
        @extend %toggleBar;

        top: var(--spacing-core-1);
    }

    &Bar2,
    &Bar3 {
        @extend %toggleBar;

        top: calc(var(--spacing-core-2) + var(--spacing-core-05));
    }

    &Bar4 {
        @extend %toggleBar;

        top: var(--spacing-core-4);
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        color: inherit;
    }
}

.isOpen {
    padding-top: var(--spacing-core-3);

    .toggleText {
        display: none;
    }

    .toggleBar1 {
        @extend %toggleBar;

        left: 50%;
        top: var(--spacing-core-2);
        width: 0;
    }

    .toggleBar2 {
        @extend %toggleBar;

        transform: rotate(45deg);
    }

    .toggleBar3 {
        @extend %toggleBar;

        transform: rotate(-45deg);
    }

    .toggleBar4 {
        @extend %toggleBar;

        left: 50%;
        top: var(--spacing-core-2);
        width: 0;
    }
}

.foldOutMenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: var(--color-background-primary-default);
    overflow: hidden;
    z-index: $z-index-header-overlay;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.2s linear;

    &IsOpen {
        transform: translateX(0);
        overflow-y: auto;
    }
}

.logo {
    padding: 0;
}
