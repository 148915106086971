@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

.dialog[open] {
    padding: 0;
}

@include mq.mq($from: tablet) {
    .dialog[open] {
        padding: 0;
    }
}

.dialogHeader {
    padding: var(--spacing-core-6);
}

.dialogTitle {
    text-align: left;
    padding: 0;
    margin: 0;
}

.dialogContentText {
    a {
        color: var(--color-action-primary-default);
        text-decoration: var(--text-decoration-core-underline);
    }
}

.checkBoxContainer {
    display: flex;
    margin-top: var(--spacing-core-6);

    p {
        margin: 0 0 0 var(--spacing-core-4);
    }
}

.dialogActions {
    padding: var(--spacing-core-8) var(--spacing-core-4);
    flex-direction: column;
    box-shadow: 0 -2px var(--sizing-core-2) 0 var(--color-background-tertiary-hover);

    @include mq.mq($from: tablet) {
        padding: var(--spacing-core-6);
    }
}

.button {
    width: 100%;
}
