@use '../../../../styles/vars' as *;
@use '../../../../styles/mq' as mq;

$z-index-dialog-backdrop: 1000000;
$z-index-dialog: $z-index-dialog-backdrop + 50;

.dialogOverlay {
    &::before {
        content: '';
        background-color: var(--color-foreground-primary-default);
        opacity: var(--opacity-core-40);
        position: fixed;
        inset: 0;
        z-index: $z-index-dialog-backdrop;
    }
}

.root {
    border-radius: var(--border-radius-core-l);
    max-height: 80%;
    max-width: 496px; /* 31 * $global-spacer-width */
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background-primary-default);
    padding: var(--spacing-core-4);
    border: none;
    position: fixed;
    z-index: $z-index-dialog;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

@include mq.mq($from: tablet) {
    .root[open] {
        padding: var(--spacing-core-6);
    }
}
