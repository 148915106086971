@use '../../styles/vars' as *;

.link {
    @extend %link;

    > * {
        color: inherit !important;
    }
}

.ahLogo {
    padding: 0 var(--spacing-core-2);
    display: flex;
    align-items: center;
}

.alignCenter {
    text-align: center;
}
