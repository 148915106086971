@use 'styles/vars' as *;
@use 'styles/mq' as mq;

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .links {
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .link {
            background-color: inherit;
        }
    }

    * + * {
        margin-left: var(--spacing-core-2);

        ::before {
            content: '-';
            display: inline-block;
            text-decoration: none;
            margin-right: var(--spacing-core-2);
        }

        :first-of-type::before {
            content: none;
        }
    }

    @include mq.mq($from: tablet) {
        flex-direction: row;

        .text::after {
            content: '|';
            margin-left: var(--spacing-core-2);
        }
    }
}
