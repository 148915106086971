$transform: calc(var(--spacing-core-1) * -1);

%mainMenuItem {
    &Anchor {
        padding: var(--spacing-core-2) var(--spacing-core-3);
        height: 100%;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: var(--color-action-primary-default);

            .mainMenuItemAnchorText::after {
                opacity: var(--opacity-core-100);
                transform: translateY(0);
            }
        }
    }

    &Text {
        transition: color 0.2s ease-in-out;
        position: relative;
        white-space: nowrap;
        color: inherit !important;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: var(--spacing-core-025);
            width: 100%;
            height: var(--sizing-core-05);
            background-color: var(--color-action-primary-default);
            transform: translateY($transform);
            opacity: 0;
            transition:
                transform 0.2s ease-in-out,
                opacity 0.2s ease-in-out;
        }
    }
}

.mainMenuItem {
    @extend %mainMenuItem;

    &Anchor {
        @extend %mainMenuItemAnchor;

        &Text {
            @extend %mainMenuItemText;
        }
    }
}

.megaMenuChevron {
    &Active {
        transform: rotate(180deg);
    }
}
