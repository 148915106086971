@use './styles/vars' as *;
@use './styles/mq' as mq;

@include mq.mq($from: desktop) {
    .mobile {
        display: none;
    }
}

@include mq.mq($to: tablet) {
    .mobile {
        height: 100%;
    }

    .desktop {
        display: none;
    }
}
