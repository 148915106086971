.navigationBasket {
    height: 100%;
    display: flex;
    align-items: center;

    .menuItemAnchor {
        color: var(--color-action-primary-default);
    }

    &PriceContent {
        padding: var(--spacing-core-1) var(--spacing-core-2);
        display: flex;
    }

    &Price {
        margin: 0 var(--spacing-core-2);
    }

    &PriceBonus {
        color: var(--color-action-discount-default);
    }
}
