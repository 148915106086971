@use '../../styles/vars' as *;

.navSkip {
    position: absolute;
    width: var(--sizing-core-025);
    height: var(--sizing-core-025);
    overflow: hidden;
    clip: rect(
        var(--sizing-core-025),
        var(--sizing-core-025),
        var(--sizing-core-025),
        var(--sizing-core-025)
    );
    white-space: nowrap;

    &:focus {
        position: fixed;
        top: var(--spacing-core-4);
        left: var(--spacing-core-4);
        z-index: calc($z-index-header-overlay + 50);
        width: auto;
        height: auto;
        padding: var(--spacing-core-2) var(--spacing-core-4);
        overflow: auto;
        clip: auto;
        color: var(--color-background-primary-default);
        background: var(--color-foreground-primary-default);
        border: calc(
                var(--border-width-core-thick) +
                    var(--border-width-core-regular)
            )
            solid var(--color-background-tertiary-disabled);
    }
}
