$suggestion-transition-timing: 0.2s ease-in-out;

.root {
    position: relative;
}

.item {
    padding: 0 var(--spacing-core-2);
}

.link {
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: var(--sizing-core-10);
    padding: var(--spacing-core-2);
    color: inherit;
    border-radius: var(--border-radius-core-s);
    position: relative;
    transition: background-color $suggestion-transition-timing;
    gap: 0;
    width: 100%;
    justify-content: flex-start;

    .text {
        line-height: var(--sizing-core-4) !important;
    }

    &:hover,
    .highlighted & {
        text-decoration: none;
        cursor: pointer;
        background-color: var(--color-action-primary-disabled);
        color: var(--color-foreground-primary-default);

        .icon {
            color: var(--color-action-primary-default);
        }

        .caret {
            opacity: var(--opacity-core-100);
        }
    }
}

.title {
    flex-grow: 1;
}

.icon {
    flex-shrink: 0;
    margin-right: var(--spacing-core-2);
    color: var(--color-foreground-primary-default);
    transition: color $suggestion-transition-timing;
}

.caret {
    position: absolute;
    top: var(--spacing-core-3);
    right: var(--spacing-core-2);
    color: var(--color-action-primary-default);
    opacity: 0;
    transition: opacity $suggestion-transition-timing;
}

.moreLink {
    color: var(--color-action-primary-default);

    &:hover {
        color: var(--color-action-primary-default);
    }

    .icon {
        color: var(--color-action-primary-default);
    }
}

.searchTerm {
    color: var(--color-foreground-primary-default);
    flex-grow: 1;
}
