@use '../../styles/vars' as *;

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    menu {
        list-style: none;
        display: flex;
        gap: var(--spacing-core-4);
        justify-content: center;
        margin: var(--spacing-core-8) 0;
        padding: 0;
    }
}

.nix {
    display: flex;
    align-items: center;

    img {
        padding-right: var(--spacing-core-2);
    }

    .button {
        @extend %link;

        display: flex;
        flex-direction: column;

        .text {
            display: flex;
            width: 100%;

            * + * {
                margin-left: var(--spacing-core-05);
            }
        }
    }
}

.image {
    display: flex;
    align-items: center;
}

.background {
    background: inherit;
}

.drug {
    border: var(--border-width-core-regular) solid
        var(--color-feedback-success-default);
}

@media screen and (width >= 1024px) {
    .root {
        flex-direction: row;
        justify-content: space-evenly;
    }
}
