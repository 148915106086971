@use '../../../../styles/vars' as *;

$transition-duration: 0.15s;
$transition-delay: 0.3s;

.megaMenuOverlay {
    left: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: $header-height;
    z-index: $z-index-header-overlay;
    width: 100%;
    border-top: var(--border-width-core-regular) solid
        var(--color-background-tertiary-hover);
    height: calc(100% - $header-height);
    visibility: hidden;
    transition: visibility 0s ease;
    transition-duration: $transition-duration;
    transition-delay: $transition-delay;

    &Open {
        visibility: visible;
    }
}

.megaMenuOverlayWithDeliveryNotification {
    top: calc($header-height + var(--spacing-core-10));
    height: calc(100% - ($header-height + var(--sizing-core-10)));
}

.megaMenuContentWrapper {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform $transition-duration ease;
    transition-delay: $transition-delay;

    &Open {
        transform: scaleY(1);
    }
}

.megaMenuBackDrop {
    height: 100%;
    background: var(--color-foreground-primary-default);
    opacity: 0;
    visibility: hidden;
    transition:
        visibility,
        opacity 0s ease;
    transition-duration: $transition-duration;
    transition-delay: $transition-delay;

    &Open {
        visibility: visible;
        opacity: var(--opacity-core-40);
    }
}
