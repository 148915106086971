@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

.root {
    position: relative;
    opacity: 0;

    &::after {
        content: "";
        display: block;
        width: 100%;
        left: 0;
        right: 0;
        height: var(--sizing-core-025);
        background-color: var(--color-background-tertiary-hover);
        opacity: var(--opacity-core-50);
    }

    @media print {
        display: none;
    }

    &.show {
        transition: opacity 300ms ease-in;
        opacity: 1;
    }
}

.wrapper {
    position: relative;
    display: flex;
    align-items:stretch;
    container-type: inline-size;
}

@include mq.mq($to: desktop) {
    .chevron {
        display: none;
    }
}

.notification {
    margin: 0 auto;
    width: $grid-width-max;
    max-width: calc($grid-width-max - var(--spacing-core-4));
}
