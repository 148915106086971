@use './styles/vars' as *;
@use './styles/mq' as mq;

.container {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 var(--spacing-core-6);
    width: 100%;
    height: var(--sizing-core-16);
    max-width: $grid-width-max;
    transition: transform 0.2s ease-out;

    .button {
        padding: 0;

        &:hover,
        &:focus {
            text-decoration: var(--text-decoration-core-underline);
        }
    }
}

@include mq.mq($from: desktop) {
    .container {
        height: var(--sizing-core-24);
    }
}
