.root {
    position: relative;
    width: var(--sizing-core-10);
    height: var(--sizing-core-10) !important;
    border-radius: var(--border-radius-core-xl);
    color: inherit;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--sizing-core-10);
        background-color: var(--color-background-tertiary-disabled);
        border-radius: var(--border-radius-core-xl);
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0);
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: var(--sizing-core-18);
        width: 100%;
        transform: translateY(-50%);
        opacity: 0;
    }

    &.active,
    &:focus,
    &:hover {
        color: inherit;

        &::before {
            transform: scale(1);
            opacity: var(--opacity-core-100);
        }
    }

    .text {
        position: relative;
    }
}

.hasContent {
    padding-right: var(--spacing-core-4);
    padding-left: var(--spacing-core-4);
    width: auto;

    .icon {
        transform: translateX(-1px);
    }
}

.outline {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 0 var(--border-width-core-regular)
        var(--color-background-tertiary-default);

    &.active,
    &:hover {
        box-shadow: 0 0 0 var(--border-width-core-regular)
            var(--color-background-tertiary-disabled);
    }
}

.icon {
    position: relative;
}
