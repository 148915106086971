@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

$transform: calc(var(--spacing-core-1) * -1);

%mainMenuItem {
    &Anchor {
        padding: var(--spacing-core-2) var(--spacing-core-3);
        height: 100%;
        width: 100%;
        position: relative;
        color: inherit;

        &:hover {
            color: var(--color-action-primary-default);
            text-decoration: none;

            .mainMenuItemAnchorText::after {
                opacity: var(--opacity-core-100);
                transform: translateY(0);
            }
        }
    }

    &Text {
        transition: color 0.2s ease-in-out;
        position: relative;
        white-space: nowrap;
        color: inherit !important;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: var(--spacing-core-025);
            width: 100%;
            height: var(--sizing-core-05);
            background-color: var(--color-action-primary-default);
            transform: translateY($transform);
            opacity: 0;
            transition:
                transform 0.2s ease-in-out,
                opacity 0.2s ease-in-out;
        }
    }
}

.mainMenuItem {
    @extend %mainMenuItem;

    &Anchor {
        @extend %mainMenuItemAnchor;

        &Text {
            @extend %mainMenuItemText;
        }
    }
}

.secondaryMenuItem {
    margin: 0 var(--spacing-core-4);
    cursor: pointer;

    + .secondaryMenuItem {
        border-top: var(--border-width-core-regular) solid
            var(--color-background-tertiary-default);
    }

    &:first-of-type {
        margin-top: var(--spacing-core-2);
    }

    &:last-of-type {
        margin-bottom: var(--spacing-core-2);
    }

    &Anchor {
        color: inherit;
        height: var(--sizing-core-16) !important;
        padding: var(--spacing-core-4) 0;
        justify-content: flex-start;
        gap: var(--spacing-core-2);
        width: 100%;

        > * {
            color: inherit !important;
        }

        &:hover {
            text-decoration: none;
            color: var(--color-action-primary-default);
        }
    }

    &Recipes:first-child {
        display: none;
        @include mq.mq($from: 1000px, $to: $header-menu-third-item-breakpoint) {
            display: list-item;
        }
    }
}

.orderLink {
    @extend %mainMenuItem;

    color: var(--color-action-primary-default);

    &Anchor {
        @extend %mainMenuItemAnchor;

        &:hover {
            .orderLinkAnchorText::after {
                opacity: var(--opacity-core-100);
                transform: translateY(0);
            }
        }

        &Text {
            color: inherit;
            @extend %mainMenuItemText;
        }
    }
}

.menuItemIcon {
    position: relative;
    float: left;
    display: block;

    + .secondaryMenuItemText {
        margin-left: var(--spacing-core-2);
        position: relative;
        display: block;
    }

    + .compactMenuItemText {
        margin-left: var(--spacing-core-2);
    }
}

.menuItemGreyedOut {
    color: var(--color-background-secondary-hover);
}

.compactMenuItem {
    list-style: none;
    cursor: pointer;
    position: relative;
    background: var(--color-background-primary-default);

    &Anchor {
        color: inherit;
        padding: var(--spacing-core-4);
        width: 100%;
        justify-content: flex-start;
        gap: var(--spacing-core-2);

        &:focus,
        &:active,
        &:focus-visible {
            outline: var(--color-action-primary-default) auto
                var(--spacing-core-025);
        }
    }

    &Text {
        position: relative;
        display: block;
    }
}

.subNavIcon {
    position: absolute;
    right: var(--spacing-core-4);
    top: 50%;
    transform: translateY(-50%);
}

.foldoutVariant {
    border-bottom: var(--border-width-core-regular) solid
        var(--color-background-tertiary-default);

    .compactMenuItemAnchor {
        height: var(--sizing-core-16);
        padding: var(--spacing-core-4) var(--spacing-core-4);

        &:hover {
            text-decoration: none;
        }
    }
}

.menuItemHeader {
    cursor: default;
}
